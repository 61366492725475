<template>
  <b-card
    v-if="!!value"
    class="border-0 rounded-0 text-center text-uppercase font-weight-light p-2"
    :bg-variant="bgVariant"
    text-variant="white"
    no-body
  >
    {{ $t(`page.results.emotionAnalyticsLabels.${valenceText}`) }}
  </b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false
    },
    isPercentageResponseLow: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    bgVariant() {
      if (this.isPercentageResponseLow) return "gray-medium";
      else return this.value < 0 ? "red-default" : "green";
    },
    valenceText() {
      return this.value < 0 ? "negative" : "positive";
    }
  }
};
</script>

<style scoped></style>
