<template>
  <div class="container">
    <div class="chart" ref="chartDiv"></div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { chartMixins } from "../../mixins";
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
  props: ["videoTime"],
  mixins: [chartMixins],
  data() {
    return {
      chartReference: null,
      animationDuration: 200,
      mainValueAxis: null,
      verticalValueAxis: null,
      ready: false,
    };
  },
  computed: {
    ...mapState("analysis", ["filters"]),
  },

  watch: {
    filters(value) {
      const { all, trend, ...rest } = value;

      if (all) {
        this.chartReference.series.values.forEach((series) => {
          series.hide(this.animationDuration);
          if (
            !series.id.includes("trend") ||
            (series.id.includes("trend") && trend)
          ) {
            series.show(this.animationDuration);
          }
        });
      } else {
        for (const filter in rest) {
          const line = this.chartReference.map.getKey(filter);
          const lineDummy = this.chartReference.map.getKey("dummy-" + filter);
          const trendLine = this.chartReference.map.getKey(filter + "-trend");

          if (line && lineDummy && trendLine) {
            line.hide(this.animationDuration);
            lineDummy.hide(this.animationDuration);
            trendLine.hide(this.animationDuration);

            if (rest[filter]) {
              line.show(this.animationDuration);
              lineDummy.show(this.animationDuration);
            }
            if (rest[filter] && trend) {
              trendLine.show(this.animationDuration);
            }
          }
        }
      }
    },
    videoTime: {
      handler(val) {
        const point = this.mainValueAxis.valueToPoint(val);
        this.chartReference.cursor.triggerMove(point, "soft");
      },
    },
  },

  mounted() {
    this.initChart();
  },

  methods: {
    resetSeriesZindex(chart) {
      chart.series.each((s) => {
        s.zIndex = 0;
      });
    },
    createSeries(chart, dummy) {
      const initialFilters = { ...this.filters };
      const showTrend = initialFilters.trend;
      for (const line in this.chartStyles) {
        const series = chart.series.push(new am4charts.LineSeries());
        series.name = line;
        series.id = !dummy ? line : "dummy-" + line;
        series.dataFields.valueX = "time";
        series.dataFields.valueY = line;
        series.stroke = am4core.color(this.chartStyles[line].color);
        series.fill = am4core.color(this.chartStyles[line].color);
        series.strokeWidth = this.chartStyles[line].strokeWidth;

        series.hiddenState.transitionDuration = 500;
        series.defaultState.transitionDuration = 500;

        series.tensionX = 0.83;
        series.tensionY = 0.83;

        series.hiddenInLegend = true;
        series.hidden = true;
        if (dummy) {
          series.zIndex = 0;
          series.strokeWidth = 40;
          series.strokeOpacity = 0.0001;
          var segment = series.segments.template;
          segment.interactionsEnabled = true;

          series.events.on(
            "over",
            debounce((event) => {
              let videoIndex = series.name.charAt(series.name.length - 1);
              videoIndex = parseInt(videoIndex);
              this.resetSeriesZindex(chart);
              series.zIndex = 1;
              chart.series.each((s) => {
                if (s.id === series.name) {
                  s.strokeWidth = 3;
                }else{
                  if(!s.id.includes("dummy")){
                    s.strokeWidth=1;
                  }
                }
              });
              if (!isNaN(videoIndex))
                this.$emit("comparisonChangeVideo", videoIndex);
            })
          );
          series.events.on("out", () => {
            const originalSeries = chart.map.getKey(series.name);
            originalSeries.strokeWidth = 1;
          });
        }

        // Apply filters if saved on store immedietely

        if (!line.includes("trend")) {
          if (initialFilters.all || initialFilters[line]) {
            series.hidden = false;
          }
        } else {
          series.hiddenInLegend = false;
          series.strokeDasharray = this.chartStyles[line].borderDash.toString();

          const l = line.split("-")[0];

          if ((initialFilters.all || initialFilters[l]) && showTrend) {
            series.hidden = false;
          }
        }
      }
    },

    initalizeSeries(chart) {
      this.createSeries(chart, false);
      this.createSeries(chart, true);
    },

    initChart() {
      const chart = am4core.create(this.$refs.chartDiv, am4charts.XYChart);
      this.chartReference = chart;
      chart.data = this.transformData(this.chartData);

      this.mainValueAxis = this.createMainValueAxis(chart);
      this.verticalValueAxis = this.createVerticalValueAxis(chart);

      chart.events.on("ready", () => {
        this.ready = true;
        this.handleCursorMove(chart);
        this.initalizeSeries(chart);
      });
    },
  },

  beforeDestroy() {
    if (this.chartReference) {
      this.chartReference.dispose();
    }
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart {
  width: 100%;
  height: 100%;
}
</style>
