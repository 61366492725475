var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend"},[(_vm.filters.attention && !_vm.filters.engagement)?[_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot mr-2",style:('background-color:' + _vm.chartStyles.attention.color)}),_c('div',{staticClass:"text mr-4"},[_vm._v(" "+_vm._s(_vm.$t("page.results.attention"))+" ")])]),(_vm.jsonVersion >= 6)?[_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot mr-2",staticStyle:{"background-color":"#3096FF"}}),_c('div',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.$t("page.results.score.attentionSpan.title"))+" ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot mr-2",staticStyle:{"background-color":"#757474"}}),_c('div',{staticClass:"text mr-4"},[_vm._v(" "+_vm._s(_vm.normLegendText)+" ")])])]:_vm._e()]:(_vm.filters.valence && !_vm.filters.engagement)?[_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot green mr-2"}),_c('div',{staticClass:"mr-4"},[_vm._v(" "+_vm._s(_vm.$t("page.results.emotionAnalyticsLabels.positive"))+" ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot red mr-2"}),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("page.results.emotionAnalyticsLabels.negative"))+" ")])])]:(!_vm.filters.engagement)?_c('b-row',_vm._l((_vm.legendValues),function(value,key){return _c('b-col',{key:key,staticClass:"mb-2",attrs:{"cols":"auto"}},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot mr-2",style:({
            backgroundColor: value.color,
            opacity: _vm.getOpacity(key)
          })}),_c('div',{staticClass:"text mr-1",style:({
            opacity: _vm.getOpacity(key)
          })},[_vm._v(" "+_vm._s(value.text)+" ")]),_c('div',{staticClass:"mr-4",style:({
            opacity: _vm.getOpacity(key)
          })},[_vm._v(" "+_vm._s(value.count)+" ")])])])}),1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }