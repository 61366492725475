<template>
  <div class="w-100">
    <transition appear @appear="setGraphWidth">
      <div
        class="graph--item"
        :style="{ backgroundColor: graphColor }"
        ref="graph_item"
      >
        <div class="text-nowrap p-2">
          {{ `${parseFloat(value).toFixed(0)}%` }}
          <b-icon
            v-if="isPercentageResponseLow"
            v-b-tooltip.hover
            :title="$t('page.results.surveyGraphTooltip')"
            icon="exclamation-triangle"
            variant="secondary"
            class="ml-3"
            style="width: 20px; height: 20px;"
          ></b-icon>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    isPercentageResponseLow: {
      type: Boolean,
      required: true
    }
  },
  watch: {
    updateGraphBarWidth: {
      handler: function() {
        this.setGraphWidth();
      },
      immediate: false
    }
  },
  computed: {
    ...mapState("analysis", ["selectedQuestion"]),
    graphColor() {
      return this.isPercentageResponseLow ? "var(--gray-medium)" : this.color;
    },
    updateGraphBarWidth() {
      return `${this.value} | ${this.selectedQuestion}`;
    }
  },
  methods: {
    setGraphWidth() {
      this.$nextTick(() => {
        /* Reset */
        this.$refs.graph_item.style.transition = "unset";
        this.$refs.graph_item.style.webkitTransition = "unset";
        this.$refs.graph_item.style.width = `${0}%`;

        setTimeout(() => {
          this.$refs.graph_item.style.transition = "width 0.4s ease-in-out";
          this.$refs.graph_item.style.webkitTransition =
            "width 0.4s ease-in-out";
          this.$refs.graph_item.style.width = `${this.value}%`;
        }, 100);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.graph--item {
  width: 0%;
  -webkit-transition: width 0.4s ease-in-out;
  -moz-transition: width 0.4s ease-in-out;
  -o-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
}
</style>
