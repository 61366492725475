<template>
  <div class="container">
    <div class="barChart"></div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { chartMixins } from "../../mixins";
import { mapState } from "vuex";

// import am4themesAnimated from "@amcharts/amcharts4/themes/animated";

// am4core.useTheme(am4themesAnimated);

export default {
  props: ["videoTime"],
  mixins: [chartMixins],
  data() {
    return {
      chart: null,
      categoryAxis: null,
      ready: false
    };
  },
  watch: {
    normText: {
      handler() {
        if (this.chart) this.chart.dispose();
        this.initChart();
      },
      immediate: false
    },
    engagementNorm: {
      handler() {
        if (this.chart) this.chart.dispose();
        this.initChart();
      },
      immediate: false
    },
    videoTime: {
      handler(val) {
        const point = this.categoryAxis.categoryToPoint(this.labelAdapter(val));
        console.log(point, this.chart);
        this.chart.cursor.triggerMove(point, "soft");
      },
      immediate: false
    }
  },
  mounted() {
    this.initChart();
  },

  computed: {
    ...mapState("analysis", ["jsonVersion"]),

    normText() {
      return `${this.$t("page.results.score.norm")} [bold #00000]${
        this.engagementNorm
      }%[/]`;
    }
  },

  methods: {
    initChart() {
      const chart = am4core.create("barChart", am4charts.XYChart);
      this.chart = chart;

      this.categoryAxis = this.createMainCategoryAxis(chart);
      this.createVerticalValueAxis(chart, true);

      chart.events.on("ready", () => {
        this.ready = true;
        this.handleCursorMove(chart, true);
      });

      chart.data = this.barChartData.map(item => ({
        ...item,
        engagement: item.engagement * 100,
        valence: item.valence * 100
      }));

      // Needs 2 dummy data for norm line issue
      chart.data = [
        { time: "dummy1", value: 20 },
        ...chart.data,
        { time: "dummy2", value: 20 }
      ];

      const series = chart.series.push(new am4charts.ColumnSeries());

      series.dataFields.valueY = this.filters.valence
        ? "valence"
        : "engagement";

      series.dataFields.categoryX = "time";
      series.columns.template.strokeWidth = 0;
      series.columns.template.adapter.add("fill", (color, target) => {
        const value = target.dataItem.valueY;
        if (value < 0) {
          return "red";
        } else {
          return "green";
        }
      });

      if (this.engagementNorm && this.jsonVersion >= 6) {
        const normSeries = chart.series.push(new am4charts.LineSeries());
        normSeries.dataFields.categoryX = "time";
        normSeries.dataFields.valueY = "norm";
        normSeries.strokeDasharray = "5,2";
        normSeries.stroke = am4core.color("#B0B0B0");
        normSeries.strokeWidth = "1px";

        normSeries.data = [
          {
            time: chart.data[0].time,
            norm: this.engagementNorm
          },
          {
            time: chart.data[chart.data.length - 1].time,
            norm: this.engagementNorm
          }
        ];

        const bullets = normSeries.bullets.push(new am4charts.LabelBullet());
        bullets.label.text = this.normText;
        bullets.label.verticalCenter = "bottom";
        bullets.label.fontSize = "10px";
        bullets.label.padding(2, 2, 2, 2);
        bullets.label.background.fill = am4core.color("#bcbcbc");
        bullets.label.fill = am4core.color("white");
        bullets.label.paddingTop = "4px";
        bullets.label.paddingLeft = "3px";
        bullets.label.horizontalCenter = "right";
        bullets.label.adapter.add("dx", (value, target) => {
          if (target && target.width) {
            return value - target.width.percent - 10;
          }
          return -40;
        });

        bullets.label.y = -5;
        bullets.align = "center";

        bullets.adapter.add("disabled", (disabled, target) => {
          if (
            target.dataItem &&
            target.dataItem.dataContext.time ===
              chart.data[chart.data.length - 1].time
          ) {
            return false;
          }
          return true;
        });
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  z-index: 20;
}
.barChart {
  width: 100%;
  height: 100%;
}
</style>
