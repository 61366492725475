<template>
  <div class="legend">
    <template v-if="filters.attention && !filters.engagement">
      <div class="item">
        <div
          :style="'background-color:' + chartStyles.attention.color"
          class="dot mr-2"
        ></div>
        <div class="text mr-4">
          {{ $t("page.results.attention") }}
        </div>
      </div>

      <template v-if="jsonVersion >= 6">
        <div class="item">
          <div style="background-color:#3096FF" class="dot mr-2"></div>
          <div class="mr-4">
            {{ $t("page.results.score.attentionSpan.title") }}
          </div>
        </div>
        <div class="item">
          <div style="background-color:#757474" class="dot mr-2"></div>
          <div class="text mr-4">
            {{ normLegendText }}
          </div>
        </div>
      </template>
    </template>

    <template v-else-if="filters.valence && !filters.engagement">
      <div class="item">
        <div class="dot green mr-2"></div>
        <div class="mr-4">
          {{ $t("page.results.emotionAnalyticsLabels.positive") }}
        </div>
      </div>

      <div class="item">
        <div class="dot red mr-2"></div>
        <div class="text">
          {{ $t("page.results.emotionAnalyticsLabels.negative") }}
        </div>
      </div>
    </template>

    <b-row v-else-if="!filters.engagement">
      <b-col
        class="mb-2"
        cols="auto"
        :key="key"
        v-for="(value, key) in legendValues"
      >
        <div class="item">
          <div
            :style="{
              backgroundColor: value.color,
              opacity: getOpacity(key)
            }"
            class="dot mr-2"
          ></div>
          <div
            :style="{
              opacity: getOpacity(key)
            }"
            class="text mr-1"
          >
            {{ value.text }}
          </div>
          <div
            :style="{
              opacity: getOpacity(key)
            }"
            class="mr-4"
          >
            {{ value.count }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  computed: {
    ...mapState("analysis", ["filters", "jsonVersion"]),

    ...mapGetters("analysis", ["legendValues", "chartStyles"]),

    normLegendText() {
      const normText = this.$t("page.results.score.norm").toLowerCase();
      const str = normText.charAt(0).toUpperCase() + normText.slice(1);
      return str;
    }
  },

  methods: {
    getOpacity(line) {
      if (this.filters.all) {
        return 1;
      } else if (this.filters[line]) {
        return 1;
      } else {
        return 0.3;
      }
    }
  }
};
</script>

<style scoped>
.dot {
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
}

.legend {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 0.8rem;
  font-weight: 300;
}

.item {
  display: flex;
  align-items: center;
  max-width: 250px;
}

.dot.red {
  background-color: red;
}

.dot.green {
  background-color: green;
}

.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
