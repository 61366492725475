import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";

export const chartMixins = {
  computed: {
    ...mapState("analysis", ["videoIsPlaying", "norms", "filters"]),
    ...mapGetters("analysis", [
      "chartData",
      "barChartData",
      "chartStyles",
      "attentionSpan",
      "attentionSpanNorm",
      "engagementNorm"
    ])
  },

  watch: {
    chartData: {
      handler: function(value, oldValue) {
        if (!_.isEqual(value, oldValue)) {
          if (this.chartReference) this.chartReference.dispose();
          this.initChart();
        }
      },
      immediate: false
    }
  },

  methods: {
    createMainCategoryAxis(chart) {
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      this.categoryAxis = categoryAxis;
      categoryAxis.title.text = "Seconds";
      categoryAxis.title.fontSize = 12;
      categoryAxis.title.fontWeight = 300;

      categoryAxis.dataFields.category = "time";
      categoryAxis.renderer.minGridDistance = 15;
      categoryAxis.renderer.labels.template.rotation = -50;
      categoryAxis.renderer.grid.template.location = 1;
      categoryAxis.renderer.labels.template.location = 0;

      categoryAxis.cursorTooltipEnabled = false;
      categoryAxis.renderer.labels.template.fontSize = 10;
      categoryAxis.renderer.labels.template.fontWeight = 300;
      categoryAxis.startLocation = 1;
      categoryAxis.endLocation = 0;

      categoryAxis.renderer.labels.template.adapter.add(
        "text",
        (text, target) => {
          if (target.dataItem) {
            const category = target.dataItem.properties.category;
            if (category && category.includes("dummy")) {
              return "";
            }
            return text;
          }

          return text;
        }
      );
      return categoryAxis;
    },

    createMainValueAxis(chart) {
      const mainValueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      mainValueAxis.title.text = "seconds";
      mainValueAxis.title.fontSize = 12;
      mainValueAxis.title.fontWeight = 300;

      mainValueAxis.renderer.minGridDistance = 15;
      mainValueAxis.renderer.labels.template.rotation = -50;
      mainValueAxis.renderer.labels.template.location = 0;

      mainValueAxis.renderer.labels.template.fontSize = 10;
      mainValueAxis.renderer.labels.template.fontWeight = 300;
      mainValueAxis.maxPrecision = 0;
      mainValueAxis.maxZoomFactor = 10;
      mainValueAxis.maxZoomCount = 10;
      mainValueAxis.maxZoomDeclination = 10;
      mainValueAxis.renderer.maxLabelPosition = 1;
      mainValueAxis.renderer.labels.template.dx = -10;

      mainValueAxis.cursorTooltipEnabled = false;
      mainValueAxis.numberFormatter.numberFormat = "#.0000";

      mainValueAxis.max = this.getMaxValue(chart.data);
      mainValueAxis.renderer.labels.template.adapter.add(
        "text",
        (text, target) => this.labelAdapter(text, target)
      );

      return mainValueAxis;
    },

    createVerticalValueAxis(chart, forBarChart = false) {
      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      if (forBarChart) {
        valueAxis.title.text = "Values";
        valueAxis.rangeChangeDuration = 0;
        valueAxis.cursorTooltipEnabled = false;
        valueAxis.renderer.labels.template.adapter.add("text", text => {
          return `${text}%`;
        });
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.fontWeight = 300;
      } else {
        valueAxis.title.text = "Values";
        valueAxis.title.fontSize = 12;
        valueAxis.title.fontWeight = 300;
        valueAxis.extraMin = 0.1;
        valueAxis.extraMax = 0.1;
        valueAxis.renderer.labels.template.fontSize = 10;
        valueAxis.renderer.labels.template.fontWeight = 300;
        valueAxis.rangeChangeDuration = 100;
        valueAxis.cursorTooltipEnabled = false;
      }
      return valueAxis;
    },

    transformData(chartData) {
      if (!Array.isArray(chartData)) {
        return [];
      }

      const transformedData = chartData.map(item => {
        if (item.time && typeof item.time === "string") {
          const regex = new RegExp(".+:.+:.+");
          let hour, minute, second;

          if (regex.test(item.time)) {
            // Includes hour
            hour = item.time.split(":")[0];
            minute = item.time.split(":")[1];
            second = item.time.split(":")[2];
          } else {
            minute = item.time.split(":")[0];
            second = item.time.split(":")[1];
          }

          let seconds = second;

          if (minute) {
            seconds = Number.parseInt(minute) * 60 + Number.parseInt(second);
          }

          if (hour) {
            seconds =
              Number.parseInt(hour) * 3600 +
              Number.parseInt(minute) * 60 +
              Number.parseInt(second);
          }
          return {
            ...item,
            time: seconds
          };
        }
        return item;
      });

      return transformedData;
    },

    handleCursorMove(chart, forBarChart = false) {
      chart.cursor = new am4charts.XYCursor();

      chart.cursor.xAxis = forBarChart ? this.categoryAxis : this.mainValueAxis;
      chart.cursor.lineX.strokeDasharray = [];
      chart.cursor.lineX.stroke = am4core.color("red");
      chart.cursor.lineX.fill = am4core.color("red");
      chart.cursor.lineY.disabled = true;
      chart.cursor.lineX.setVisibility(true);
      chart.cursor.lineX.fillOpacity = forBarChart ? 0.2 : 1;
      chart.cursor.fullWidthLineX = forBarChart;
      chart.events.on("over", () => {
        this.$emit("hover", true);
      });

      chart.cursor.events.on("cursorpositionchanged", event => {
        if (!this.videoIsPlaying) {
          const seconds = forBarChart
            ? this.categoryToSeconds(
                this.categoryAxis.positionToCategory(event.target.xPosition)
              )
            : this.mainValueAxis.positionToValue(
                this.mainValueAxis.toAxisPosition(event.target.xPosition)
              );

          if (!isNaN(seconds)) {
            this.$emit("cursorPositionChange", seconds);
          }
        }
      });

      const point = forBarChart
        ? this.categoryAxis.categoryToPoint(":00")
        : this.mainValueAxis.valueToPoint(0);
      chart.cursor.triggerMove(point, "soft");
    },

    getMaxValue(chartData) {
      const timeValues = chartData.map(item => {
        if (item && item.time !== null && item.time !== undefined) {
          return item.time;
        } else {
          return -1;
        }
      });

      const maxValue = Math.max(...timeValues);
      return maxValue;
    },

    categoryToSeconds(category) {
      const regex = new RegExp(".+:.+:.+");
      let hour, minute, second;
      if (category && typeof category === "string") {
        if (regex.test(category)) {
          // Includes hour
          hour = category.split(":")[0];
          minute = category.split(":")[1];
          second = category.split(":")[2];
        } else {
          minute = category.split(":")[0];
          second = category.split(":")[1];
        }

        let seconds = second;

        if (minute) {
          seconds = Number.parseInt(minute) * 60 + Number.parseInt(second);
        }

        if (hour) {
          seconds =
            Number.parseInt(hour) * 3600 +
            Number.parseInt(minute) * 60 +
            Number.parseInt(second);
        }

        return seconds;
      }
    },

    labelAdapter(text, target) {
      const val = parseInt(text);
      let seconds = parseInt(text);

      const hours = Math.floor(seconds / (60 * 60)); // Calculate hours from seconds
      seconds = val - hours * 60 * 60; // Find remaining seconds

      const minutes = Math.floor(seconds / 60); // Calculate minutes from seconds
      seconds = seconds - minutes * 60; // Find remaining seconds

      let category = "0:" + (seconds <= 9 ? "0" + seconds : seconds);

      if (minutes !== 0) {
        category =
          (minutes <= 9 ? "0" + minutes : minutes) +
          ":" +
          (seconds <= 9 ? "0" + seconds : seconds);
      }

      if (hours !== 0) {
        category =
          (hours <= 9 ? "0" + hours : hours) +
          ":" +
          (minutes <= 9 ? "0" + minutes : minutes) +
          ":" +
          (val <= 9 ? "0" + seconds : seconds);
      }
      return category;
    }
  }
};
