<template>
  <b-card
    v-if="!!value"
    class="border-0 rounded-0 text-center p-2 h-100"
    :bg-variant="bgVariant"
    text-variant="white"
    no-body
  >
    {{ `${value}%` }}
  </b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: false
    },
    isPercentageResponseLow: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    bgVariant() {
      if (this.isPercentageResponseLow) return "gray-medium";
      else {
        if (this.value < 50) return "red-default";
        else if (this.value < 70) return "orange";
        else return "green";
      }
    }
  }
};
</script>

<style scoped></style>
