<template>
  <b-card class="border-0 rounded-0 mb-3">
    <h4
      class="text-red"
      style="font-family: RobotoCondensed !important; font-weight: 300; font-size: 1.1rem"
    >
      {{ $t("page.results.survey") }}
    </h4>

    <div class="text-secondary mb-5" style="font-size: 0.9em;font-weight: 300">
      {{ questionText }}
    </div>

    <b-overlay
      :show="isSurveyInvalid"
      opacity="0.4"
      variant="white"
      rounded="sm"
    >
      <template class="text-center" slot="overlay">
        Error in data. Some values may not be properly displayed.
      </template>
      <survey-graph :chartData="questionAnswers" />
    </b-overlay>
  </b-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import orderBy from "lodash/orderBy";
import SurveyGraph from "./SurveyGraph";

export default {
  components: {
    SurveyGraph
  },
  computed: {
    ...mapState("analysis", ["videoIndex", "selectedQuestion", "questions"]),
    ...mapGetters("analysis", {
      styleData: "chartStyles"
    }),
    questionText() {
      return this.questions[this.videoIndex][this.selectedQuestion].text;
    },
    questionAnswers() {
      const data = Object.entries(this.styleData)
        .filter(([key]) => !key.includes("-trend"))
        .map(([key, value]) => ({
          text: value.text,
          percentageResponse: this.percentageStringToNumber(value.count),
          percentageColor: value.color,
          attention: value.attention,
          engagement: value.engagement,
          valence: value.valence
        }));

      return orderBy(data, "percentageResponse", "desc");
    },
    isSurveyInvalid() {
      const invalidIndex = this.questionAnswers.findIndex(
        answer =>
          !(
            !!answer.text &&
            answer.percentageResponse >= 0 &&
            !!answer.percentageColor &&
            answer.attention >= 0 &&
            answer.engagement >= 0 &&
            answer.valence &&
            typeof answer.valence === "number"
          )
      );
      return invalidIndex > -1;
    }
  },
  methods: {
    percentageStringToNumber(count) {
      if (count && typeof count === "string" && count.includes("%"))
        return parseFloat(count.split("%"));
      else if (count && typeof count === "number") return count;
      else return 0;
    }
  }
};
</script>

<style scoped></style>
