<template>
  <div class="container">
    <div class="chart" ref="chartDiv"></div>
  </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { chartMixins } from "../../mixins";

import am4themesAnimated from "@amcharts/amcharts4/themes/animated";
import { mapState } from "vuex";

am4core.useTheme(am4themesAnimated);

export default {
  mixins: [chartMixins],
  props: ["videoTime"],
  data() {
    return {
      chartReference: null,
      animationDuration: 500,
      mainValueAxis: null,
      ready: false
    };
  },
  watch: {
    normText: {
      handler() {
        this.initialize();
      },
      immediate: false
    },
    norms: {
      handler() {
        this.initialize();
      },
      immediate: false,
      deep: true
    },
    chartData: {
      handler() {
        this.initialize();
      },
      immediate: false,
      deep: true
    },
    videoTime: {
      handler(val) {
        const point = this.mainValueAxis.valueToPoint(val);
        this.chartReference.cursor.triggerMove(point, "soft");
      }
    }
  },

  mounted() {
    this.initialize();
  },

  computed: {
    ...mapState("analysis", ["jsonVersion"]),

    normText() {
      return `${this.$t("page.results.score.norm")} [bold #00000]${
        this.norms.attention
      }%[/]`;
    }
  },

  methods: {
    initialize() {
      const chart = am4core.create(this.$refs.chartDiv, am4charts.XYChart);
      this.chartReference = chart;
      chart.data = this.transformData(this.chartData).map(item => ({
        ...item,
        attention: item.attention * 100
      }));

      if (this.jsonVersion >= 6) {
        chart.data = [
          ...chart.data,
          {
            attentionVertical: "attention",
            attentionSpan: this.attentionSpan,
            attentionSpanNorm: this.attentionSpanNorm
          }
        ];
      }

      chart.events.on("ready", () => {
        this.ready = true;
        this.handleCursorMove(chart);
      });

      const mainValueAxis = chart.xAxes.push(new am4charts.ValueAxis());
      this.mainValueAxis = mainValueAxis;
      mainValueAxis.title.text = "seconds";
      mainValueAxis.title.fontSize = 12;
      mainValueAxis.title.fontWeight = 300;

      mainValueAxis.renderer.minGridDistance = 0.02;
      mainValueAxis.renderer.labels.template.rotation = -50;
      mainValueAxis.renderer.labels.template.location = 0;

      mainValueAxis.renderer.labels.template.fontSize = 10;
      mainValueAxis.renderer.labels.template.fontWeight = 300;
      mainValueAxis.maxPrecision = 0;
      mainValueAxis.maxZoomFactor = 10;
      mainValueAxis.maxZoomCount = 10;
      mainValueAxis.maxZoomDeclination = 10;
      mainValueAxis.renderer.maxLabelPosition = 1;
      mainValueAxis.renderer.labels.template.dx = -10;

      mainValueAxis.cursorTooltipEnabled = false;
      mainValueAxis.numberFormatter.numberFormat = "#.00";

      mainValueAxis.max = this.getMaxValue(chart.data);
      mainValueAxis.renderer.labels.template.adapter.add(
        "text",
        (text, target) => this.labelAdapter(text, target)
      );

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = "Values";
      valueAxis.title.fontSize = 12;
      valueAxis.title.fontWeight = 300;
      valueAxis.extraMin = 0.1;
      valueAxis.extraMax = 0.1;
      valueAxis.paddingBottom = 8;

      valueAxis.renderer.labels.template.fontSize = 10;
      valueAxis.renderer.labels.template.fontWeight = 300;
      valueAxis.renderer.labels.template.adapter.add("text", text => {
        return `${text}%`;
      });
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.rangeChangeDuration = 0;

      const series = chart.series.push(new am4charts.LineSeries());

      series.name = "attention";
      series.id = "attention";
      series.stroke = am4core.color(this.chartStyles.attention.color);
      series.dataFields.valueX = "time";
      series.dataFields.valueY = "attention";

      series.tensionX = 0.83;
      series.tensionY = 0.83;

      function createSeries(field, name, barColor) {
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.yAxis = categoryAxis;
        series.dataFields.valueX = field;
        series.dataFields.categoryY = "attentionVertical";
        series.name = name.toUpperCase();
        series.columns.template.height = am4core.percent(100);
        series.sequencedInterpolation = true;
        series.fill = am4core.color(barColor);
        series.stroke = am4core.color(barColor);
        series.tooltip.disabled = true;

        series.columns.template.events.on("sizechanged", function(ev) {
          if (ev.target.dataItem && ev.target.dataItem.bullets) {
            const width = ev.target.pixelWidth;
            ev.target.dataItem.bullets.each(function(id, bullet) {
              if (
                bullet.label.currentText &&
                ((bullet.label.currentText.includes("NORM") && width < 180) ||
                  (!bullet.label.currentText.includes("NORM") && width < 130))
              ) {
                bullet.dx = chart.zoomFactor * width + 10;
                bullet.label.fill = ev.target.fill;
              } else {
                bullet.dx = 10;
                bullet.label.fill = am4core.color("white");
              }
            });
          }
        });

        var categoryLabel = series.bullets.push(new am4charts.LabelBullet());
        categoryLabel.id = "{name}";
        categoryLabel.fontSize = 10;
        categoryLabel.label.text = "{name}: [bold]{valueX}[/] sec";
        categoryLabel.locationX = 1;

        categoryLabel.label.horizontalCenter = "left";
        categoryLabel.label.verticalCenter = "middle";
        categoryLabel.label.truncate = false;
      }

      if (this.jsonVersion >= 6) {
        /*******************
         *
         *
         */

        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "attentionVertical";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 0;
        categoryAxis.renderer.startLocation = 0;
        categoryAxis.height = 60;
        categoryAxis.paddingBottom = 8;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.tooltip.disabled = true;

        chart.leftAxesContainer.layout = "vertical";

        createSeries("attentionSpan", "Attention Span", "#3096FF");
        createSeries("attentionSpanNorm", "Attention Span Norm", "#757474");

        /*******************
         *
         */

        const normSeries = chart.series.push(new am4charts.LineSeries());
        normSeries.dataFields.valueX = "time";
        normSeries.dataFields.valueY = "norm";
        normSeries.strokeDasharray = "5,2";
        normSeries.stroke = am4core.color("gray");
        normSeries.data = [
          {
            time: chart.data[0].time,
            norm: this.norms.attention
          },
          {
            time: chart.data[chart.data.length - 2].time,
            norm: this.norms.attention
          }
        ];

        const bullets = normSeries.bullets.push(new am4charts.LabelBullet());
        bullets.label.text = this.normText;
        bullets.label.verticalCenter = "bottom";
        bullets.label.width = "150px";
        bullets.label.fontSize = "0.7rem";
        bullets.label.padding(2, 2, 2, 2);
        bullets.label.fill = am4core.color("gray");
        bullets.label.paddingTop = "5px";
        bullets.label.paddingLeft = "5px";
        bullets.align = "center";

        bullets.adapter.add("disabled", (disabled, target) => {
          if (
            target.dataItem &&
            target.dataItem.dataContext.time ===
              chart.data[chart.data.length - 2].time
          ) {
            return false;
          }
          return true;
        });
      }
    }
  },

  beforeDestroy() {
    if (this.chartReference) {
      this.chartReference.dispose();
    }
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart {
  width: 100%;
  height: 100%;
}
</style>
