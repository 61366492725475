<template>
  <div class="px-3 pt-3 p_b__3">
    <b-container fluid>
      <b-row>
        <b-col class="p-0 pr-4" cols="12" lg="4">
          <video-selector
            :videoTime="chartPoint"
            :comparisonIndex="comparisonVideoIndex"
            @videoTimeUpdate="$event => updateVideoTime($event)"
            @play="onPlay($event)"
            @fixedupdate="onFixedUpdate($event)"
          ></video-selector>
          <emotion-tabs class="mt-2"></emotion-tabs>
        </b-col>
        <b-col class="p-0" cols="12" lg="8">
          <template v-if="selectedTab === 2 && !comparison && jsonVersion >= 6">
            <survey />
          </template>

          <b-card style="border-radius: 0" border-variant="light">
            <b-row>
              <b-col>
                <h4
                  class="text-red"
                  style="font-family: RobotoCondensed !important; font-weight: 300; font-size: 1.1rem"
                >
                  {{ graphTitle }}
                </h4>
              </b-col>
            </b-row>

            <div v-if="activeChart === 'curves'">
              <emotions-chart
                @hover="onHover($event)"
                @cursorPositionChange="onCursorPositionChange"
                @comparisonChangeVideo="onComparisonChangeVideo($event)"
                :videoTime="videoTime"
              ></emotions-chart>
            </div>
            <div v-else-if="activeChart === 'bar'">
              <bar-chart
                @hover="onHover($event)"
                @cursorPositionChange="onCursorPositionChange"
                :videoTime="videoTime"
              ></bar-chart>
            </div>
            <div v-else>
              <attention-chart
                @hover="onHover($event)"
                @cursorPositionChange="onCursorPositionChange"
                :videoTime="videoTime"
              />
            </div>

            <chart-legend></chart-legend>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import VideoSelector from "../components/Results/VideoSelector.vue";
import EmotionTabs from "../components/Results/EmotionTabs";
import EmotionsChart from "../components/Results/EmotionsChart";
import BarChart from "../components/Results/BarChart";
import AttentionChart from "../components/Results/AttentionChart";
import Survey from "../components/Results/Survey";
import ChartLegend from "../components/Results/ChartLegend.vue";

export default {
  components: {
    VideoSelector,
    EmotionTabs,
    EmotionsChart,
    BarChart,
    AttentionChart,
    Survey,
    ChartLegend
  },
  data() {
    return {
      videoTime: 0,
      hovering: false,
      chartPoint: 0,
      comparisonVideoIndex:0
    };
  },
  watch: {
    comparison() {
      this.setActiveChart("curves");
    }
  },


  computed: {
    ...mapState("analysis", [
      "videoIsPlaying",
      "selectedEmotion",
      "selectedMetric",
      "selectedDemographic",
      "selectedQuestion",
      "videoIndex",
      "activeChart",
      "selectedTab",
      "filters",
      "videoData",
      "projectQuestions",
      "questions",
      "customSegments",
      "ageRanges",
      "genderInfo",
      "errors",
      "norms",
      "jsonVersion"
    ]),

    ...mapGetters("analysis", [
      "video",
      "answers",
      "comparison",
      "graphTitle",
      "selectedSegment",
      "chartData",
      "chartStyles",
      "barChartData"
    ])
  },

  methods: {
    updateVideoTime(event){
      this.videoTime = event;
    },

    onFixedUpdate(event) {
      this.videoTime = event;
    },

    onCursorPositionChange(event) {
      if (!this.videoIsPlaying) {
        this.chartPoint = event;
      }
    },

    onHover(event) {
      if (event && this.videoIsPlaying) {
        this.$store.commit("analysis/SET_VIDEO_IS_PLAYING", false);
      }
    },


    onComparisonChangeVideo(newComparisonVideoIndex){
      this.comparisonVideoIndex=newComparisonVideoIndex;
    },

    onPlay(event) {
      this.$store.commit("analysis/SET_VIDEO_IS_PLAYING", event);
    },

    ...mapMutations("analysis", {
      setActiveChart: "SET_ACTIVE_CHART"
    }),

    setFilters() {
      const filters = { ...this.filters };
      for (const filter in filters) {
        filters[filter] = false;
      }

      if (this.activeChart === "bar") {
        filters.engagement = true;
      } else {
        filters.all = true;
        filters.trend = true;
      }

      this.$store.commit("analysis/SET_FILTERS", filters);
    }
  }
};
</script>

<style scoped>
.inner-nav {
  margin-top: 0;
  margin-bottom: 10px;
  position: relative;
  left: 220px;
  top: -10px;
}

.inner-nav > span:hover {
  cursor: pointer;
  opacity: 0.7;
}

.active {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--red);
}

.chart-icon {
  opacity: 0.5;
}
.chart-icon:hover {
  cursor: pointer;
}

.activeChart {
  opacity: 1;
}

.custom-select {
  font-size: 0.8rem;
  font-weight: 400;
}
</style>
