<template>
  <div>
    <b-card v-if="comparison" style="border-radius: 0" border-variant="white">
      <b-row class="mb-2">
        <b-col class="px-2">
          <b-form-select
            class="custom-select"
            :value="selectedEmotion"
            @change="
              setEmotion($event);
              resetFilters();
            "
          >
            <b-form-select-option :key="em" v-for="em of emotions" :value="em">
              {{ $t(`page.results.emotionAnalyticsLabels.${em}`) }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="px-2 my-1 m-0" cols="12" lg="4">
          <b-button
            class="text-small"
            @click="clickedAll()"
            :pressed.sync="filters.all"
            size="sm"
            variant="outline-gray-dark"
            block
          >
            {{ $t("page.results.allVideos") }}
          </b-button>
        </b-col>
        <b-col class="px-2 my-1" cols="12" lg="4">
          <b-button
            class="text-small"
            @click="clickedTrend()"
            :pressed.sync="filters.trend"
            size="sm"
            variant="outline-gray-dark"
            block
          >
            {{ $t("page.results.trend") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          :key="i"
          v-for="(video, i) in videoData"
          class="px-2 my-1"
          cols="12"
          lg="4"
        >
          <b-button
            class="text-small"
            @click="changeFilters()"
            :pressed.sync="filters['video' + i]"
            size="sm"
            variant="outline-gray-dark"
            block
          >
            {{ videoData[i].name }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs
      v-if="!comparison"
      :value="selectedTab"
      @input="
        setTab($event);
        setActiveChart('curves');
        resetFilters();
      "
      no-fade
      class="bg-gray-light"
      active-nav-item-class="bg-white"
      active-tab-class="bg-white"
      pills
      fill
    >
      <b-tab
        class="pb-4"
        title-link-class="px-1 py-3 text-gray-dark custom-tab no-border"
      >
        <template v-slot:title>
          <div class="d-flex align-items-center justify-content-center">
            <img
              class="icon"
              width="20"
              src="../../assets/chart-icons/Emotions.svg"
            />
            <div class="ml-2">{{ $t("page.results.emotions") }}</div>
          </div>
        </template>

        <div class="p-4">
          <b-row class="mb-3">
            <b-col class="px-1">
              {{ $t("page.results.primaryEmotions") }}
            </b-col>
          </b-row>

          <b-row>
            <b-col class="px-1 my-1 m-0" cols="12" lg="4">
              <b-button
                class="text-small"
                @click="clickedAll()"
                :pressed.sync="filters.all"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.allEmotions") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                @click="clickedTrend()"
                :pressed.sync="filters.trend"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.trend") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                @click="changeFilters()"
                :pressed.sync="filters.happiness"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.emotionAnalyticsLabels.happiness") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                @click="changeFilters()"
                :pressed.sync="filters.surprise"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.emotionAnalyticsLabels.surprise") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                @click="changeFilters()"
                :pressed.sync="filters.anger"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.emotionAnalyticsLabels.anger") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                @click="changeFilters()"
                :pressed.sync="filters.disgust"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.emotionAnalyticsLabels.disgust") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                @click="changeFilters()"
                :pressed.sync="filters.fear"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.emotionAnalyticsLabels.fear") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                @click="changeFilters()"
                :pressed.sync="filters.sadness"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.emotionAnalyticsLabels.sadness") }}
              </b-button>
            </b-col>
          </b-row>

          <b-row class="mt-4 mb-3">
            <b-col class="px-2">{{ $t("page.results.metrics") }}</b-col>
          </b-row>
          <b-row>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.attention"
                @click="clickedAttention()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.attention") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.engagement"
                @click="clickedEngagement()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.engagement") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.valence"
                @click="clickedValence()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.valence") }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-tab>

      <b-tab
        class="pb-4"
        title-link-class="px-1 py-3 text-gray-dark custom-tab no-border"
      >
        <template v-slot:title>
          <div class="d-flex align-items-center justify-content-center">
            <img
              class="icon"
              width="20"
              src="../../assets/chart-icons/Demographics.svg"
            />
            <div class="ml-2">{{ $t("page.results.demographics") }}</div>
          </div>
        </template>

        <div class="p-4">
          <b-row>
            <b-col class="px-2">
              <b-form-select
                class="custom-select"
                :value="selectedDemographic"
                @change="setDemographic($event)"
              >
                <b-form-select-option value="gender">
                  {{ $t("gender") }}
                </b-form-select-option>
                <b-form-select-option value="age">
                  {{ $t("age") }}
                </b-form-select-option>
                <b-form-select-option
                  :value="'custom-' + index"
                  :key="index"
                  v-for="(segment, index) of customSegments[videoIndex]"
                >
                  {{ segment.text }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col class="px-2">
              <b-form-select
                class="custom-select"
                :value="selectedEmotion"
                @change="setEmotion($event)"
              >
                <b-form-select-option
                  :key="em"
                  v-for="em of emotions"
                  :value="em"
                >
                  {{ $t(`page.results.emotionAnalyticsLabels.${em}`) }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.all"
                @click="clickedAll()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.all") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.trend"
                @click="clickedTrend()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.trend") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row v-if="selectedDemographic === 'gender'">
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.male"
                @click="changeFilters()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("male") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.female"
                @click="changeFilters()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("female") }}
              </b-button>
            </b-col>
            <b-col v-if="jsonVersion >= 6" class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.other"
                @click="changeFilters()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.emotionAnalyticsLabels.other") }}
              </b-button>
            </b-col>
          </b-row>

          <b-row v-else-if="selectedDemographic === 'age'">
            <b-col
              :key="index"
              v-for="(ageRange, index) of ageRanges"
              class="px-1 my-1"
              cols="12"
              lg="4"
            >
              <b-button
                class="text-small"
                :pressed.sync="filters['agerange' + index]"
                @click="changeFilters()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ ageRange.text }}
              </b-button>
            </b-col>
          </b-row>

          <b-row v-else>
            <b-col
              :key="i"
              v-for="(s, i) of customSegments[videoIndex][selectedSegment]
                .values"
              class="px-1 my-1"
              cols="12"
              xl="4"
            >
              <b-button
                style="height: 100%"
                class="text-small"
                :pressed.sync="filters['value' + i]"
                @click="changeFilters()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ s.text }}
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-tab>

      <b-tab
        v-if="questions[videoIndex] && questions[videoIndex].length !== 0"
        class="pb-4"
        title-link-class="px-1 py-3 text-gray-dark custom-tab no-border"
      >
        <template v-slot:title>
          <div class="d-flex align-items-center justify-content-center">
            <img
              class="icon"
              width="20"
              src="../../assets/chart-icons/Segments.svg"
            />
            <div class="ml-2">{{ $t("page.results.surveys") }}</div>
          </div>
        </template>

        <div class="p-4">
          <b-row>
            <b-col class="px-2">
              <b-form-select
                class="custom-select"
                :value="selectedQuestion"
                @change="
                  setQuestion($event);
                  resetFilters();
                "
              >
                <b-form-select-option
                  :key="index"
                  v-for="(q, index) of questions[videoIndex]"
                  :value="index"
                >
                  {{ q.text }}
                  <!-- {{ $t("page.results.intentionToBuy") }} -->
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col class="px-2">
              <b-form-select
                v-if="jsonVersion < 6"
                class="customs-select"
                :value="selectedEmotion"
                @change="setEmotion($event)"
              >
                <b-form-select-option
                  :key="emotion"
                  v-for="emotion of emotions"
                  :value="emotion"
                >
                  {{ $t(`page.results.emotionAnalyticsLabels.${emotion}`) }}
                </b-form-select-option>
              </b-form-select>

              <b-form-select
                v-else
                class="customs-select"
                :value="selectedMetric"
                @change="setMetric($event)"
              >
                <b-form-select-option
                  :key="metric"
                  v-for="metric of surveyMetrics"
                  :value="metric"
                >
                  {{ $t(`page.results.emotionAnalyticsLabels.${metric}`) }}
                </b-form-select-option>
              </b-form-select>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.all"
                @click="clickedAll()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.all") }}
              </b-button>
            </b-col>
            <b-col class="px-1 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.trend"
                @click="clickedTrend()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.trend") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              :key="i"
              v-for="(a, i) of questions[videoIndex][selectedQuestion].answers"
              class="px-1 my-1"
              cols="12"
              xl="4"
            >
              <b-button
                style="height: 100%"
                class="text-small"
                :pressed.sync="filters['answer' + i]"
                @click="changeFilters()"
                size="sm"
                :variant="getButtonVariant(i)"
                block
              >
                {{ a.text }}
              </b-button>
            </b-col>
            <!-- <b-col class="px-2 my-1" cols="12" lg="4">
              <b-button
                class="text-small"
                :pressed.sync="filters.negative"
                @click="changeFilters()"
                size="sm"
                variant="outline-gray-dark"
                block
              >
                {{ $t("page.results.emotionAnalyticsLabels.negative") }}
              </b-button>
            </b-col> -->
          </b-row>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
export default {
  data() {
    return {
      currentTab: 0,
      emotions: [
        "happiness",
        "surprise",
        "anger",
        "disgust",
        "fear",
        "sadness"
      ],
      surveyMetrics: ["attention", "engagement"],
      filters: {}
    };
  },

  created() {
    console.log(this.comparison);
    this.filters = { ...this.storedFilters };

    this.videoData.forEach((video, i) => {
      this.$set(this.filters, "video" + i, false);
    });

    this.ageRanges.forEach((ageRange, i) => {
      this.$set(this.filters, "agerange" + i, false);
    });

    if (
      this.customSegments[this.videoIndex] &&
      this.customSegments[this.videoIndex][this.selectedSegment]
    ) {
      this.customSegments[this.videoIndex][this.selectedSegment].values.forEach(
        (custom, i) => {
          this.$set(this.filters, "value" + i, false);
        }
      );
    }

    if (
      this.questions[this.videoIndex] &&
      this.questions[this.videoIndex][this.selectedQuestion]
    ) {
      this.questions[this.videoIndex][this.selectedQuestion].answers.forEach(
        (answer, i) => {
          this.$set(this.filters, "answer" + i, false);
        }
      );
    }

    this.$store.commit("analysis/SET_FILTERS", { ...this.filters });
  },

  watch: {
    comparison() {
      this.resetFilters();
    },

    selectedQuestion() {
      if (
        this.questions[this.videoIndex] &&
        this.questions[this.videoIndex][this.selectedQuestion]
      ) {
        this.questions[this.videoIndex][this.selectedQuestion].answers.forEach(
          (answer, i) => {
            this.$set(this.filters, "answer" + i, false);
          }
        );
      }

      this.ageRanges.forEach((ageRange, i) => {
        this.$set(this.filters, "agerange" + i, false);
      });

      this.resetFilters();
    },

    selectedDemographic() {
      if (
        this.customSegments[this.videoIndex] &&
        this.customSegments[this.videoIndex][this.selectedSegment]
      ) {
        this.customSegments[this.videoIndex][
          this.selectedSegment
        ].values.forEach((custom, i) => {
          this.$set(this.filters, "value" + i, false);
        });
      }
      this.resetFilters();
    },

    videoIndex() {
      if (!this.comparison) {
        if (
          this.questions[this.videoIndex] &&
          this.questions[this.videoIndex][this.selectedQuestion]
        ) {
          this.questions[this.videoIndex][
            this.selectedQuestion
          ].answers.forEach((answer, i) => {
            this.$set(this.filters, "answer" + i, false);
          });
        }
        this.ageRanges.forEach((ageRange, i) => {
          this.$set(this.filters, "agerange" + i, false);
        });

        if (
          this.customSegments[this.videoIndex] &&
          this.customSegments[this.videoIndex][this.selectedSegment]
        ) {
          this.customSegments[this.videoIndex][
            this.selectedSegment
          ].values.forEach((custom, i) => {
            this.$set(this.filters, "value" + i, false);
          });
        }

        if (this.selectedTab === 2) {
          this.resetFilters();
        }
      }
    },

    storedFilters(value) {
      this.filters = { ...value };
    }
  },

  computed: {
    ...mapState("analysis", [
      "selectedTab",
      "selectedEmotion",
      "selectedMetric",
      "selectedDemographic",
      "selectedQuestion",
      "videoIndex",
      "activeChart",
      "videoData",
      "projectQuestions",
      "questions",
      "customSegments",
      "jsonVersion"
    ]),

    ...mapState("analysis", {
      storedFilters: "filters"
    }),

    ...mapGetters("analysis", {
      selectedSegment: "selectedSegment",
      answerPercentages: "answerPercentages",
      ageRanges: "ageRanges",
      comparison: "comparison"
    })
  },

  methods: {
    ...mapMutations("analysis", {
      setTab: "SET_TAB",
      setDemographic: "SET_DEMOGRAPHIC",
      setSegment: "SET_SEGMENT",
      setQuestion: "SET_QUESTION",
      setEmotion: "SET_EMOTION",
      setMetric: "SET_METRIC",
      setActiveChart: "SET_ACTIVE_CHART"
    }),

    getButtonVariant(index) {
      if (this.answerPercentages[index] < 6) {
        return "outline-gray-medium";
      }
      return "outline-gray-dark";
    },

    clickedAll() {
      if (!this.filters.all) {
        this.filters.happiness = true;
        this.filters.agerange0 = true;
        this.filters.male = true;
        this.filters.positive = true;
        this.filters.video0 = true;
      } else {
        for (const filter in this.filters) {
          if (filter !== "all" && filter !== "trend") {
            this.filters[filter] = false;
          }
        }
      }
      this.setActiveChart("curves");
      this.$store.commit("analysis/SET_FILTERS", { ...this.filters });
    },

    changeFilters() {
      this.filters.all = false;
      this.filters.engagement = false;
      this.filters.valence = false;
      this.filters.attention = false;
      this.setActiveChart("curves");
      this.$store.commit("analysis/SET_FILTERS", { ...this.filters });
    },

    clickedTrend() {
      this.$store.commit("analysis/SET_FILTERS", { ...this.filters });
    },

    clickedValence() {
      for (const filter in this.filters) {
        this.filters[filter] = false;
      }
      this.filters.valence = true;
      this.setActiveChart("bar");
      this.$store.commit("analysis/SET_FILTERS", { ...this.filters });
    },

    clickedAttention() {
      for (const filter in this.filters) {
        this.filters[filter] = false;
      }
      this.filters.attention = true;
      this.setActiveChart("attention");
      this.$store.commit("analysis/SET_FILTERS", { ...this.filters });
    },

    clickedEngagement() {
      for (const filter in this.filters) {
        this.filters[filter] = false;
      }
      this.filters.engagement = true;
      this.setActiveChart("bar");
      this.$store.commit("analysis/SET_FILTERS", { ...this.filters });
    },

    resetFilters() {
      for (const filter in this.filters) {
        this.filters[filter] = false;
      }
      this.filters.all = true;
      this.setActiveChart("curves");
      this.$store.commit("analysis/SET_FILTERS", { ...this.filters });
    }
  }
};
</script>

<style>
a.bg-white:hover,
a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background: white !important;
}
.text-small {
  font-size: 0.8rem;
  font-weight: 400;
}

.no-border {
  border-radius: 0 !important;
  outline: none !important;
}

.custom-tab {
  font-size: 0.8em;
  font-weight: 700;
}

.custom-select {
  font-size: 0.8rem;
  font-weight: 400;
}

.icon {
  filter: invert(80%) sepia(30%) saturate(40%) hue-rotate(90deg) brightness(50%)
    contrast(30%);
}

.custom-tab:hover .icon {
  filter: invert(80%) sepia(30%) saturate(40%) hue-rotate(90deg) brightness(0%)
    contrast(40%);
}
</style>
