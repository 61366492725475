<template>
  <b-row class="mb-4 align-items-center">
    <b-col
      cols="2"
      :class="{ 'text-gray-medium': isPercentageResponseLow }"
      style="font-size: 0.9em;font-weight: 300"
    >
      {{ item.text }}
    </b-col>

    <b-col cols="5">
      <survey-graph-bar
        :value="item.percentageResponse"
        :color="item.percentageColor"
        :isPercentageResponseLow="isPercentageResponseLow"
      />
    </b-col>

    <b-col cols="5">
      <b-row v-if="isPercentagePositive" class="no-gutters ml-n3">
        <b-col class="pl-2" cols="4">
          <survey-stat-card
            :value="item.attention"
            :isPercentageResponseLow="isPercentageResponseLow"
          />
        </b-col>
        <b-col class="pl-2" cols="4">
          <survey-stat-card
            :value="item.engagement"
            :isPercentageResponseLow="isPercentageResponseLow"
          />
        </b-col>
        <b-col class="pl-2" cols="4">
          <survey-valence-card
            :value="item.valence"
            :isPercentageResponseLow="isPercentageResponseLow"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import SurveyGraphBar from "./SurveyGraphBar";
import SurveyStatCard from "./SurveyStatCard";
import SurveyValenceCard from "./SurveyValenceCard";

export default {
  components: {
    SurveyGraphBar,
    SurveyStatCard,
    SurveyValenceCard
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    isPercentagePositive() {
      return this.item.percentageResponse > 0;
    },
    isPercentageResponseLow() {
      return this.item.percentageResponse < 6;
    }
  }
};
</script>

<style scoped></style>
