<template>
  <div>
    <b-col offset="7" cols="5" class="mb-3">
      <b-row style="font-size:0.9rem" class="no-gutters text-center">
        <b-col class="pl-2" cols="4">
          <h6 style="font-size:0.9rem">{{ $t("page.results.attention") }}</h6>
        </b-col>
        <b-col class="pl-2" cols="4">
          <h6 style="font-size:0.9rem">{{ $t("page.results.engagement") }}</h6>
        </b-col>
        <b-col class="pl-2" cols="4">
          <h6 style="font-size:0.9rem">{{ $t("page.results.valence") }}</h6>
        </b-col>
      </b-row>
    </b-col>

    <template v-for="(item, index) in chartData">
      <survey-graph-item :item="item" :key="index" />
    </template>
  </div>
</template>

<script>
import SurveyGraphItem from "./SurveyGraphItem";

export default {
  components: {
    SurveyGraphItem
  },
  props: {
    chartData: {
      type: Array,
      required: true
    }
  }
};
</script>

<style scoped></style>
